import * as React from "react"
import styled from "styled-components"
import CompanyCardBrief from '../CompanyCardBrief/index'

const CardGrid = styled.div`
  display: grid;
  grid-gap: .5rem;
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr); 
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr); 
  }
`

const StackedCardList = ({ cards }) => {
  return (
    <section id="favorite-stores">
      <CardGrid>
        {cards.map((el, i) => <CompanyCardBrief key={i} isCardLink {...el} />)}
      </CardGrid>
    </section>
  )
}

export default StackedCardList
