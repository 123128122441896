import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
    text-transform: uppercase;
    div:nth-child(1) {
    }
    div:nth-child(2) {
        font-weight: bolder;
        font-size: 1.6rem;
    }
    div:nth-child(3) {
        font-weight: 600;
    }
`

function StackedCouponDetailsStat({ couponMainTitle, couponBoldFeature, couponFeatureDetails }) {
    return (
        <Wrapper>
            <div className="main-title">{couponMainTitle}</div>
            <div className="bold-feature">{couponBoldFeature}</div>
            <div className="feature-details">{couponFeatureDetails}</div>
        </Wrapper>
    )
}

StackedCouponDetailsStat.propTypes = {

}

export default StackedCouponDetailsStat

