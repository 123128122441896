import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SearchBar from '../components/SearchBar'
import StackedCardList from '../components/StackedCardList'
import { generateStackOfStoreCards, generateStackOfCouponCards } from '../utils/faker-data'
import CouponCardStack from '../components/CouponCardStack'
import SectionHeader from '../components/SectionHeader/index'
import Layout from '../components/layout'
import { color } from '../settings'
const IntroSection = styled.div`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
`

const IntroHeader = styled.h1`
  color: ${color.darkest};
  text-align: center;
  font-size: 3rem;
  font-weight: bolder;
  `

const IntroText = styled.div`
  text-align: center;
  color: ${color.darkest};
  font-size: 1.5rem;
`

const StyledSection = styled.section`
  padding-top: 30px;
  h2 {
      margin: 0px;
  }
`
// [] where's SEO?

function index(props) {
    return (
        <Layout>
            <StyledSection>
                <IntroSection>
                    <IntroHeader>
                        Great new deals every day!
                    </IntroHeader>
                    <IntroText>
                        <p>In publishing and graphic design, Lorem ipsum is on meaningful content. Lorem ipsum may be used as a placeholder before final copy</p>
                    </IntroText>
                </IntroSection>
            </StyledSection>
            <StyledSection>
                <SearchBar />
            </StyledSection>
            <StyledSection>
                <StackedCardList cards={generateStackOfStoreCards(15)} />
            </StyledSection>
            <StyledSection>
                <SectionHeader >Save with our best coupons!</SectionHeader>
                <CouponCardStack cards={generateStackOfCouponCards(12)} />
            </StyledSection>
            <StyledSection>
                <SectionHeader >More of our favorite stores</SectionHeader>
                <StackedCardList cards={generateStackOfStoreCards(15)} />
            </StyledSection>
        </Layout>
    )
}

index.propTypes = {

}

export default index

