import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color } from '../../settings'
import { Icon, Input } from 'semantic-ui-react'

const StyledBar = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    /* align-items: center; */
    background-color: ${color.medium};
    color: ghostwhite;
    & > div {
        flex-grow: 1;
        text-align: center;
    }
    .search-side {
        display: none
    };
    @media(min-width: 500px) {
        .search-side {
            display: unset
        };
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div:nth-child(2) {
            flex-grow: 3;
        }
        align-items: center;
    }
    div.under-search {
        font-size: 0.75rem;
        color: ${color.lightest};
        text-align: left;
    }

`

function SearchBar(props) {
    return (
        <StyledBar>
            <div className="search-side">
                <div style={{ textAlign: 'left', display: 'inline-block' }}>
                    <b>Find your store</b><br />and get so much more.
                </div>
            </div>
            <div>
                <Input size='small' icon placeholder='Search...' fluid                 >
                    <input />
                    <Icon name='search' />
                </Input>
                <div className="under-search">Find more of what'you're looking for</div>
            </div>
            <div className="search-side">
                <div style={{ textAlign: 'left', display: 'inline-block' }}>
                    <b>Find your store</b><br />and get so much more.
                </div>
            </div>
        </StyledBar>
    )
}

SearchBar.propTypes = {

}

export default SearchBar

