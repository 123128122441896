import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InitialsBadge from '../InitialsBadge/index'
import LogoBox from '../LogoBox/index'
import TextStat from '../TextStat/index'
import StackedCouponDetailsStat from '../StackedCouponDetailsStat'
import RevealButton from '../RevealButton/index'
import Media from 'react-responsive';
import GlowCard from '../GlowCard'

const SubtleHr = styled.hr`
    border: .1px solid #ddd;
    color: transparent;
    margin-top: 10px;
    width: 80%
`

const UniversalRow = styled.div`
    line-height: 1.25em;
    justify-content: space-between;
    display: flex;
    padding: 5px;
    & > div:nth-child(1) {
        width: 24%;
    }
    align-items: center;
    @media (max-width: 400px) {
        flex-direction: column;
        .reveal-button {
            margin-top: 10px;
        }
    }
    @media (max-width: 600px) {
        & > div:nth-child(1) {
            width: unset;
        }
    }
`

const IntroGroup = styled.div`
    cursor: pointer;
    display: flex;
    div.img-flex {
        padding-right: 10px;
    }
`

const MobileStatsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: scale(75%);
`

function CouponLineDetailsCard({
    storeThumbSrc,
    storeLinkUrl,
    isLogoSquared,
    couponMainTitle,
    couponBoldFeature,
    couponFeatureDetails,
    couponLastUsed,
    couponQtyUsedTimes,
    couponSuccessRate,
    couponCodeTeaser,
    couponLinkUrl,
}) {
    return (
        <GlowCard>
            <UniversalRow>
                <IntroGroup onClick={() => {
                    console.log('clicked')
                    // navigate.. you can't use Gatsby Link?
                    // storeLinkUrl
                }}>
                    <div className="img-flex">
                        {storeThumbSrc ?
                            <LogoBox imgSrc={storeThumbSrc} squared={isLogoSquared} /> :
                            <InitialsBadge name={couponMainTitle} squared={isLogoSquared} />}
                    </div>
                    <div>
                        <StackedCouponDetailsStat
                            couponMainTitle={couponMainTitle}
                            couponBoldFeature={couponBoldFeature}
                            couponFeatureDetails={couponFeatureDetails}
                        />
                    </div>
                </IntroGroup>
                <Media minWidth="600px">
                    <TextStat label="Coupon used:" stat={couponQtyUsedTimes} />
                    <TextStat label="Last used:" stat={couponLastUsed} />
                    <TextStat label="Success rate:" stat={couponSuccessRate} />
                </Media>
                <div className="reveal-button">
                    <RevealButton couponCodeTeaser={couponCodeTeaser}/>
                </div>
            </UniversalRow>

            <Media maxWidth="599px">
                <SubtleHr />
                <MobileStatsRow>
                    <TextStat label="Coupon used:" stat={couponQtyUsedTimes} />
                    <TextStat label="Last used:" stat={couponLastUsed} />
                    <TextStat label="Success rate:" stat={couponSuccessRate} />
                </MobileStatsRow>
            </Media>
        </GlowCard>
    )
}

export default CouponLineDetailsCard

CouponLineDetailsCard.propTypes = {
    imgSrc: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    labelLink: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    isLogoSquared: PropTypes.bool,
}

