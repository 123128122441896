import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.div`
    @keyframes shake {
        40%, 80% {
            transform: translate3d(-2px, 0, 0);
        }
        20%, 60% {
            transform: translate3d(2px, 0, 0);
        }
    }
    & {
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 10px;
        position: relative;
        color: #444;
        font-size: 1.5rem;
        text-align: right;
        width: 160px;
        height: 40px;
        border-radius: 3px;
        background-color: #eee;
        border: 1px solid #ddd;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
    }
    &::before {
        font-size: 1rem;
        padding-left: 20px;
        content: 'GET CODE';
        color: ghostwhite;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 130px;
        height: 40px;
        border-radius: 3px 0 0 3px;
        background-color: #78bbc4;
        clip-path: polygon(0 0, 120px 0, 130px 40px, 0 40px ) ;
    }
    &:hover{
        animation: shake 0.5s;
        &::before {
            filter: brightness(1.2);
        }
        &::after {
            filter: brightness(1.2);
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 100px;
        top: 0;
        width: 30px;
        height: 40px;
        border-radius: 3px 0 0 3px;
        background-color: #5897a0;
        clip-path: polygon(20px 0, 30px 40px, 0 10px) ;
    }
`

function RevealButton({ couponCodeTeaser }) {
    return (
        <div>
            <StyledButton>{couponCodeTeaser}</StyledButton>
        </div>
    )
}

RevealButton.defaultProps = {
    couponCodeTeaser: '42'
}

RevealButton.propTypes = {
    couponCodeTeaser: PropTypes.string.isRequired
}

export default RevealButton

