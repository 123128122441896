import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const pastels = ['#ada7fc', '#ec6f86', '#ffdd75', '#9ff3c3', '#4573e7', '#45b4e7']

const InitialsBadge = styled.div`
    font-family: 'Open Sans Condensed', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.75em;
    color: white;
    display: flex;
    width: 50px;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-transform: uppercase;
    background-color: ${({ bgColor }) => bgColor || pastels[Math.floor(Math.random() * pastels.length)]};
    border-radius: ${({squared}) => squared ? '5px' : '25px'};
`

const NameCircle = ({ name, bgColor, squared }) => {
    var initials;
    if (name.length < 3) initials = name;
    if (!initials) initials = name.match(/\b[a-z]/ig).join('').substring(0, 2);
    return (
        <InitialsBadge
         bgColor={bgColor}
         squared={squared}
         >{initials}</InitialsBadge>
    )
}

NameCircle.propTypes = {

}

export default NameCircle

