import React from 'react'
import PropTypes from 'prop-types'
import CouponLineDetailsCard from '../CouponLineDetailsCard/index'
import styled from 'styled-components'

const CouponStack = styled.div`
    display: grid;
    grid-gap: .5rem;
`

function CouponCardStack({ cards }) {
    if (!cards) return <div>cardless</div>
    return (
        <CouponStack>
            {cards.map((card, i) => <CouponLineDetailsCard key={i} {...card} />)}
        </CouponStack>
    )
}

CouponCardStack.propTypes = {

}

export default CouponCardStack

