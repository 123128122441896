import React from 'react'
import styled from 'styled-components'
import { color } from '../../settings'


const StyledHeader = styled.div`
    background-color: ${color.medium};
    font-size: 1.2rem;
    text-align: center;
    color: ghostwhite;
    padding: 5px;
    margin-bottom: 0.5rem;
    /* text-transform: capitalize; */
`

function SectionHeader({ children }) {
    return (
        <StyledHeader>
            <h2>{children}</h2>
        </StyledHeader>
    )
}

export default SectionHeader
