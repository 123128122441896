import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import sizeOf from 'image-size'

const ImgBox = styled.img`
    border-radius: ${({ squared }) => squared ? '5px' : '25px'};
    width: 50px;
    height: 50px;
    object-fit: cover;
`

function LogoBox({ imgSrc, squared }) {
    // try {
    //     const { width, height } = sizeOf(imgSrc);
    //     if (width > 150 || height > 150) console.log('LogoBox was passed a rather large image');
    // } catch (err) {
    //     console.error(`LogoBox image doesn't seem to be an image at all.`);
    // }

    return (
        <ImgBox src={imgSrc} squared={squared} />
    )
}

LogoBox.propTypes = {
    imgSrc: PropTypes.string,
    squared: PropTypes.bool,
}

export default LogoBox

