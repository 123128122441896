import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InitialsBadge from '../InitialsBadge/index'
import LogoBox from '../LogoBox/index'
import GlowCard from '../GlowCard'


const StyleWrapper = styled.div`
    display: flex;
    align-items: center;
    span.subtitle {
        font-weight: 300;
    }
    div.img-flex {
        padding-right: 10px;
    }
    div.main {
        max-height: 2.5em;
        overflow: hidden;
        font-weight: bold;
    }
    div.label-link {
        font-weight: 300;
        font-size: 0.9em;
        margin-top: 0;
    }
    a {
        color: #234;
    }

`

function CompanyCardBrief({ imgSrc, storeName, subtitle, labelLink, linkUrl, squared, isCardLink }) {
    return (
        <GlowCard isCardLink={isCardLink}>
            <StyleWrapper>
                <div className="img-flex">
                    {imgSrc ?
                        <LogoBox imgSrc={imgSrc} squared={squared} /> :
                        <InitialsBadge name={storeName} squared={squared} />}
                </div>
                <div>
                    <div className='main'>
                        {storeName} <span className='subtitle'>{subtitle}</span>
                    </div>
                    <div className='label-link'>
                        <a href='#'>{labelLink}</a>
                    </div>
                </div>
            </StyleWrapper>
        </GlowCard>
    )
}

export default CompanyCardBrief

CompanyCardBrief.propTypes = {
    imgSrc: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    labelLink: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    squared: PropTypes.bool,
}