import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
    text-align: center;
    h4, h5 {
        margin: 0;
    }
    h5 {
        font-weight: lighter;
        font-size: 0.75rem;
    }
    h4 {
        font-weight: normal;
        font-size: 1.2rem;
    }
`

function TextStat({ label, stat }) {
    return (
        <Wrapper>
            <h5>{label}</h5>
            <h4>{stat}</h4>
        </Wrapper>
    )
}

TextStat.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
    ]),
    stat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
    ])
}

export default TextStat

